<template lang="pug">
    .main-wrapper.origem-atendimento-listar
        header.main-wrapper-header
            .p-grid.p-align-center
                .p-col-12.ta-right
                    h1.text-header.text-secondary MedClub / <b>Origens de Atendimento</b>

        Dialog.dialog-salvar(:header="`${ model.id ? 'Editar' : 'Cadastrar' } Origem`"
            :visible.sync='dialogSalvar' :modal='true' @hide="model={ ds_origem: ''}; submitted=false")
            form(@submit.prevent="salvarOrigem()")
                .p-grid.p-fluid.p-align-end()
                    .p-col-12(:class="{ 'form-group--error': submitted && $v.model.ds_origem.$error }")
                        label.form-label Origem *:
                        InputText(v-model='$v.model.ds_origem.$model')
                        .feedback--errors(v-if='submitted && $v.model.ds_origem.$error')
                            .form-message--error(v-if="!$v.model.ds_origem.minLength") Deve ter pelo menos 2 caracteres.
                            .form-message--error(v-if="!$v.model.ds_origem.required") Campo obrigatório.

                    .p-col-12.mt-2(v-if="model.id")
                        .p-grid
                            .p-col-12.p-md-6.aux-2
                                span <b>Usuário criação: </b> {{ model.cd_usuario_cri.username }}
                            .p-col-12.p-md-6.aux-2
                                span <b>Data da criação: </b> {{ model.dt_criado_f }}
                            .p-col-12.p-md-6.aux-2
                                span <b>Usuário edição: </b> {{ model.cd_usuario_edi.username }}
                            .p-col-12.p-md-6.aux-2
                                span <b>Data da atualização: </b> {{ model.dt_atualizado_f }}

                .ta-center.mt-4
                    ProgressSpinner.spinner-small(v-if='waitingSalvar' strokeWidth='6')
                    Button(v-else label='Salvar' type="submit")

        Panel.mb-2(header='Filtros' :toggleable='true' :collapsed='windowInnerWidth < 576')
            .p-grid.p-fluid.p-align-end

                .p-col-12.p-md-8
                    label.form-label Origem:
                    .p-inputgroup
                        InputText(
                            placeholder='Origem'
                            :autoClear='false'
                            @keyup.enter.native='applyFilters()'
                            @input='val => val || applyFilters()'
                            v-model='filters.ds_origem'
                        )
                        Button(icon='jam jam-search' @click='applyFilters()')

                .p-col-12.p-md-4
                    label.form-label Status:
                    Dropdown(
                        v-model='filters.ie_status'
                        :options='filtersOptions.ieStatus'
                        optionLabel='text'
                        optionValue='value'
                        dataKey='value'
                        placeholder='TODOS'
                        @change='applyFilters()'
                    )

        .ta-right.mb-4
            Button(label='Adicionar' icon="jam jam-plus" @click="dialogSalvar=true")

        ProgressBar(v-if='waiting' mode="indeterminate")

        div(v-else-if='!list.length')
            p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.

        div(v-else)
            DataView.dataview.my-2(:value="list" layout="grid")
                template(#grid="props")
                    .p-col-12(style="padding: .5em" :class="{ disabled: !props.data.ie_status }")
                        Panel.panel-list.ta-center(:header="props.data.ds_origem" style='position: relative')
                            .ta-left
                                p <b>Origem:</b> {{ props.data.ds_origem }}

            Paginator.dataview(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")

            Panel.datatable(header='Lista de Origens')
                DataTable(:value="list" @sort="onSort($event)" :sortField="order.field" :sortOrder="order.sortOrder")
                    Column(headerStyle='width: 5%;' bodyStyle='text-align: center;')
                        template(#body='props')
                            ProgressSpinner.spinner-small(v-if='waitingStatusId === props.data.id' strokeWidth='6')
                            .status-indicator(v-else :class="{ off: ! props.data.ie_status }" @click='alterarStatus(props.data)')
                                i.jam(:class="{ 'jam-check': props.data.ie_status, 'jam-minus': !props.data.ie_status }")
                    Column(headerStyle='width: 50%; text-align: left' field='ds_origem' header='Origem' sortable)
                    Column(headerStyle='width: 15%;' bodyStyle='text-align: center' header='Dt. Criação')
                        template(#body='props')
                            span(v-tooltip.top="`Usuário: ${props.data.cd_usuario_cri.username}`") {{ props.data.dt_criado_f }}
                    Column(headerStyle='width: 15%;' bodyStyle='text-align: center' header='Dt. Edição')
                        template(#body='props')
                            span(v-tooltip.top="`Usuário: ${props.data.cd_usuario_edi.username}`") {{ props.data.dt_atualizado_f }}
                    Column(headerStyle='width: 15%;' header='Ações')
                        template(#body='props')
                            .ta-center
                                Button.p-button-raised.p-button-rounded.mr-1(
                                    v-tooltip.top="'Editar'"
                                    icon="jam jam-write"
                                    @click="model=props.data;dialogSalvar=true"
                                )
                Paginator(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")

</template>

<style lang="scss">
    .origem-atendimento-listar {
        .dataview {
            @media all and (min-width: 577px) {
                display: none;
            }
        }
        .datatable {
            @media all and (max-width: 576px) {
                display: none;
            }
        }
        .dialog-salvar {
            width: 600px;
            max-width: 98%;
        }
        .spinner-small {
            max-width: 23px;
            height: auto;
        }
        .status-indicator {
            display: inline-block;
            font-size: 0;
            width: 23px;
            height: 23px;
            line-height: 36px;
            border-radius: 50%;
            background-color: #94c860;
            border: 1px solid #84b553;
            transition: 250ms;
            cursor: pointer;
            padding-right:10px;
            &:hover { background-color: #84b553; }
            &.off {
                background-color: #d33838;
                border: 1px solid #b33030;
                &:hover { background-color: #b33030; }
            }
            .jam {
                color: #fff;
                margin-left:2px;
            }
        }
    }
</style>

<script>
    import Button from "primevue/button"
    import Column from "primevue/column"
    import DataTable from "primevue/datatable"
    import DataView from "primevue/dataview"
    import Dialog from "primevue/dialog"
    import Dropdown from 'primevue/dropdown'
    import InputText from 'primevue/inputtext'
    import Paginator from 'primevue/paginator'
    import Panel from "primevue/panel"
    import ProgressBar from "primevue/progressbar"
    import ProgressSpinner from 'primevue/progressspinner'
    import Tooltip from "primevue/tooltip"

    import wsConfigs from "@/middleware/configs"
    import {Agendamento} from "../../middleware"
    import {minLength, required} from "vuelidate/lib/validators"
    import moment from "moment"

    export default {
        components: { Button, Column, DataTable, DataView, Dialog, Dropdown, InputText,
            Paginator, Panel, ProgressBar, ProgressSpinner, Tooltip },
        directives: { tooltip: Tooltip },
        created() {
            this.applyFilters()
        },
        data() {
            return {
                model: {
                    ds_origem: ""
                },
                list: [],
                submitted: false,
                waiting: false,
                waitingStatusId: 0,
                waitingSalvar: false,
                dialogSalvar: false,
                filters: {
                    ie_status: 'true',
                    ds_origem: "",
                },
                filtersOptions: {
                    ieStatus: [
                        { text: "TODOS", value: null },
                        { text: "Ativo", value: 'true' },
                        { text: "Inativo", value: 'false' },
                    ]
                },
                order: {
                    field: 'ds_origem',
                    sortOrder: 1
                },
                paginator: {
                    page: this.$route.query.pg || 1,
                    per_page: wsConfigs.paginator_perPage,
                    count: 0
                },
            }
        },
        validations () {
            return {
                model: {
                    ds_origem: { required, minLength: minLength(2) },
                }
            }
        },
        watch: {
            dialogSalvar: function (val) {
                if (val === false) this.submitted = false
            },
        },
        methods: {
            getList(params) {
                this.waiting = true
                Agendamento.origemAtendimentoFindAll(params).then(response => {
                    this.waiting = false
                    if (response.status === 200) {
                        this.paginator.count = response.data.count
                        this.list = response.data.results
                        this.list.forEach(origem => {
                            origem.cd_usuario_cri = origem.cd_usuario_cri || {}
                            origem.cd_usuario_edi = origem.cd_usuario_edi || {}
                            if (origem.dt_criado) {
                                origem.dt_criado_f = moment(origem.dt_criado).format('DD/MM/YYYY HH:mm')
                            }
                            if (origem.dt_atualizado) {
                                origem.dt_atualizado_f = moment(origem.dt_atualizado).format('DD/MM/YYYY HH:mm')
                            }
                        })
                    } else if (response.data.detail) {
                        if (typeof response.data.detail == 'string')
                            this.$toast.error(response.data.detail, { duration: 3000 })
                        else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                    }
                })
            },
            applyFilters() {
                if (this.$route.query.pg != this.paginator.page) this.$router.replace( { query: { pg: this.paginator.page } } )

                let params = { paginacao: true, page: this.paginator.page, per_page: this.paginator.per_page }
                params.order = `${ this.order.sortOrder === 1 ? '' : '-' }${ this.order.field }`

                Object.keys(this.filters).forEach((key) => {
                    if (this.filters[key] !== null && this.filters[key] !== "") params[key] = this.filters[key]
                })
                this.getList(params)
            },
            salvarOrigem() {
                this.submitted = true

                this.$v.$touch()
                if (this.$v.$invalid) return 0

                let dataSend = { ds_origem: this.model.ds_origem }
                if (this.model.id) dataSend.id = this.model.id

                this.waitingSalvar = true
                Agendamento.origemAtendimentoSave(dataSend).then(response => {
                    this.waitingSalvar = false
                    if (response.status === 200) {
                        this.$toast.success("Origem salva com sucesso.")
                        this.dialogSalvar = false
                        this.model = { ds_origem: "" }
                        this.applyFilters()
                    } else if (response.data.detail) {
                        if (typeof response.data.detail == 'string')
                            this.$toast.error(response.data.detail, { duration: 3000 })
                        else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                    }
                })
            },
            alterarStatus(origem) {
                let dataSend = { id: origem.id, ie_status: !origem.ie_status }

                this.waitingStatusId = origem.id
                Agendamento.origemAtendimentoSave(dataSend).then(response => {
                    this.waitingStatusId = 0
                    if (response.status === 200) {
                        origem.ie_status = !origem.ie_status
                        this.$toast.success("Status alterado com sucesso.")
                    } else if (response.data.detail) {
                        if (typeof response.data.detail == 'string')
                            this.$toast.error(response.data.detail, { duration: 3000 })
                        else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                    }
                })
            },
            onPage (ev) {
                this.paginator.page = ev.page + 1
                this.applyFilters()
            },
            onSort(ev) {
                this.order.field = ev.sortField
                this.order.sortOrder = ev.sortOrder
                this.applyFilters()
            },
        },
    }
</script>
